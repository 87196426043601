import React from 'react';

const StaticHeader = () => {
  const originURL = new URL(window.location.href).origin
  return (
    <span style={{width:"100%"}} dangerouslySetInnerHTML={{
      __html: `<iframe frameborder="0" src="${originURL}/${process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_IFRAME_HTML_HEADER_PATH}" width="100%" height="auto" style="width:100%; height: auto;border:unset;"></iframe>`
    }}></span>
  );
};

export default StaticHeader;