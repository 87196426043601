import React, { useEffect, useState } from 'react';
import { api } from '@iblai/ibl-web-react-common';
import { getProviderImgUrl, retrieveIDFromProviderUrl } from '../../helpers';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useTranslation } from 'react-i18next';

function getEdxSsoUrl(url, edxURL, clientId) {
  const removeFrom = url.indexOf('next=%2Fdashboard');
  url = url.slice(0, removeFrom);

  let nextUrl = `/oauth2/authorize?response_type=code&client_id=${clientId}&scope=profile email&redirect_uri=${window.location.origin}/login/complete`;
  nextUrl = encodeURIComponent(nextUrl);
  return `${edxURL}${url}next=${nextUrl}`;
}

const staticProviders = process.env.REACT_APP_IBL_STATIC_PROVIDERS
  ? JSON.parse(process.env.REACT_APP_IBL_STATIC_PROVIDERS)
  : [];

const MfeContextUI = () => {
  const [mfeContext, setMfeContext] = useState(
    staticProviders.map((provider) => ({
      ...provider,
      iconClass: getProviderImgUrl(provider.name),
      url: getEdxSsoUrl(
        provider.loginUrl,
        process.env.REACT_APP_IBL_LMS_URL,
        process.env.REACT_APP_IBL_SSO_CLIENT_ID
      ),
    }))
  );
  const { t } = useTranslation();
  const [mfeContextLoading, setMmfeContextLoading] = useState(false);
  const handleMFEContextLoad = async () => {
    if (staticProviders.length === 0) {
      setMmfeContextLoading(true);
    }
    api.ibledxmfe.getMfeContext(async (data) => {
      if (
        data &&
        Array.isArray(data?.context_data?.providers) &&
        data.context_data.providers.length > 0
      ) {
        const url = new URL(window.location.href);
        const urlOrigin = url.origin;
        let providers = [];
        const staticProviderIds = staticProviders.map(
          (provider) => provider.id
        );
        await data.context_data.providers.forEach((provider) => {
          const pickedID = retrieveIDFromProviderUrl(provider?.loginUrl);
          if (!staticProviderIds.includes(provider?.id)) {
            providers.push({
              name: provider?.name,
              id: pickedID,
              iconClass: getProviderImgUrl(provider?.name),
              url: getEdxSsoUrl(
                provider?.loginUrl,
                process.env.REACT_APP_IBL_LMS_URL,
                process.env.REACT_APP_IBL_SSO_CLIENT_ID
              ),
            });
          }
        });
        setMfeContext([...mfeContext, ...providers]);
      } else {
        setMfeContext([...mfeContext]);
      }
      setMmfeContextLoading(false);
    });
  };

  useEffect(() => {
    handleMFEContextLoad();
  }, []);

  return (
    <>
      {mfeContext.length > 0 && (
        <div className="w-layout-hflex auth-seperation-block">
          <div className="div-block-36"></div>
          <div className="text-block-30">OR</div>
          <div className="div-block-36"></div>
        </div>
      )}
      {mfeContextLoading ? (
        <IBLSpinLoader
          color={'var(--accent, #2467eb)'}
          size={30}
          containerHeight={'100px'}
        />
      ) : (
        <div className="w-layout-vflex external-auth-block">
          {mfeContext &&
            mfeContext.map((provider, index) => (
              <a
                key={index}
                href={provider.url}
                className="external-auth-container w-inline-block"
              >
                <div className="w-layout-hflex external-auth-btn">
                  <img
                    src={provider.iconClass}
                    loading="lazy"
                    alt=""
                    className="image-31"
                  />
                  <div className="external-auth-label">
                    {t('Continue with')} {provider.name}
                  </div>
                </div>
              </a>
            ))}
        </div>
      )}
    </>
  );
};

export default MfeContextUI;
