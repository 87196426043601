import $ from "jquery"
import 'jquery-ui'
import googleImg from "../assets/images/google-color-svgrepo-com.svg"
import microsoftImg from "../assets/images/microsoft-svgrepo-com1.svg"
import appleImg from "../assets/images/apple-svgrepo-com.svg"

export const removeSpaceFromText = (text) => {
    return String(text).trim().replace( /\s\s+/g, ' ' );
}

export const  showFlex = (element, isStringElement=true) =>{
    if(!!isStringElement){
        $(element).attr('style', 'display:flex')
    }else{
        $(element).attr('style', 'display:flex')
    }

}

export function validateEmail(email) {
    // Updated regex pattern to validate email format with domain and extension
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Additional check to ensure the domain part contains at least one dot
    const domainPart = email.split('@')[1];
    console.log({domainPart})

    if (!domainPart || domainPart.split('.').length < 2) {
        return false;
    }

    return regex.test(email);
}

export const handleTextHelper = () => {
    $(document).on('input', 'input, select, textarea', function(){
        if($(this).val()===""){
            $(this).closest('.auth-input-group').find('.text-helper').removeClass('hidden')
        }else{
            $(this).closest('.auth-input-group').find('.text-helper').addClass('hidden')
        }
    })
}

export const handlePasswordEyeClick = ({setPasswordType, isPasswordHidden, setIsPasswordHidden}) =>{
    $(document).on('click', 'form.auth-form .password-eye-block', function () {
        setIsPasswordHidden(!isPasswordHidden)
        //$(this).find('img').toggleClass('hidden')
        const passwordInputEl = $(this).closest('.auth-input-group').find('input')
        const currentPasswordInputType = passwordInputEl.attr('type')
        if (currentPasswordInputType === "text") {
            setPasswordType("password")
            //passwordInputEl.attr('type', "password")
        } else {
            setPasswordType("text")
            //passwordInputEl.attr('type', "text")
        }
    })
}

export const handleInvalidFieldFocus = () => {
    //MAKE DISAPPEAR INVALID FIELD & TEXT ERROR ON FOCUS
    $('form.auth-form input').on('focus input', function () {
        $(this).removeClass('invalid')
        $(this).closest('.auth-input-group').find('.text-error').addClass('hidden')
    })
}

export const handleNotificationCloseClick = () =>{
    $('.notification-close').on('click', function () {
        //$(this).closest('.notification-element').hide("slide", {direction: "right"}, 200);
        $(this).closest('.notification-element').hide();
    })
}

export const resetLoginForm = () => {
    const form = $('form.auth-form')
    form.find('.auth-input-group[data-auth-level="2"]').removeClass("visible")
    form.find('.auth-input-group[data-auth-level="1"]').fadeIn('', () => {
        $(this).addClass('visible')
        form.find('.auth-input-group[data-auth-level="2"]').removeClass('visible')
        form.attr('data-auth-actual-level', '1')
    })
    form.find('.auth-submit-btn').removeClass('disabled')
    form.find('.auth-submit-btn-label').text('Continue')
    form.find('.btn-spinner-block').addClass('hidden')
}

export const resetForgotPasswordForm = () => {
    const form = $('form.auth-form')
    form.find('.auth-submit-btn').removeClass('disabled')
    form.find('.auth-submit-btn-label').text('Continue')
    form.find('.btn-spinner-block').addClass('hidden')
}

export const handleFormSubmitTrigger = () => {
    $('form.auth-form').on('submit', function (e) {
        e.preventDefault();
        $(this).find('.auth-submit-btn:not(.disabled)').trigger('click')
        return false
    })
}

export const retrieveIDFromProviderUrl = (url, splitter="/auth/login/") => {
    const splitLevel1 = String(url).split(splitter)
    if(splitLevel1.length > 1){
        const splitLevel2 = String(splitLevel1[1]).split("/")
        return splitLevel2[0]
    }
    return "#"
}

export const getProviderImgUrl = (providerName) =>{
    const providersImg = {
        google:googleImg,
        microsoft:microsoftImg,
        apple:appleImg,
    }
    return providersImg?.[String(providerName).toLowerCase()] || googleImg
}

export function slugify(str) {
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
}

export const defaultSPALogoData = {
    logo:process.env.PUBLIC_URL+"/images/logo.png",
    favicon:process.env.PUBLIC_URL+"/images/favicon.png",
    height:process.env.REACT_APP_IBL_LOGO_HEIGHT
}

export const googleTrialAPI = async (data, successCallback, errorCallback) => {
    const url = " https://learn.mentor.iblai.pro/api/ibl/saas/launch/tenant/"
     await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((resp)=>{
        successCallback(resp.json())
     }).catch((err)=>{
         errorCallback(err)
     })
}

//SIMILARITY BETWEEN 2 STRINGS
/**
 * Checks if two strings are similar based on a similarity threshold.
 *
 * @param {string} s1 - The first string to compare.
 * @param {string} s2 - The second string to compare.
 * @param {number} [limit=0.5] - The similarity threshold, default is 0.5.
 * @returns {boolean} Returns `true` if the similarity between the strings is greater than or equal to the threshold, otherwise `false`.
 */
export function stringsAreSimilar(s1, s2, limit=0.5){
    if(similarityBetweenStrings(s1, s2) >= 0.5){
        return true
    }
    return false
}

function similarityBetweenStrings(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
        return 1.0;
    }
    return (longerLength - editDistanceBetweenStrings(longer, shorter)) / parseFloat(longerLength);
}



function editDistanceBetweenStrings(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i == 0)
                costs[j] = j;
            else {
                if (j > 0) {
                    var newValue = costs[j - 1];
                    if (s1.charAt(i - 1) != s2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue),
                          costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

export function getUrlParams(url) {
    // Create a URL object
    const urlObj = new URL(url);

    // Get the URLSearchParams object
    const searchParams = urlObj.searchParams;

    // Create an object to store the parameters
    const params = {};

    // Iterate over all parameters and add them to the object
    for (const [key, value] of searchParams) {
        params[key] = value;
    }

    return params;
}
